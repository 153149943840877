import React from 'react';

function Filmography({ fetchedData }) {
  return (
    <div>
      Filmography
    </div>
  );
}

export default Filmography;
