import React from 'react';

function Seasons({ fetchedData }) {
  return (
    <div>
      Seasons
    </div>
  );
}

export default Seasons;
