import React from 'react';

function CastCrew({ fetchedData }) {
  return (
    <div>
      Cast & CrewSSS
    </div>
  );
}

export default CastCrew;
